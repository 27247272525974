<template>
  <div id="app">
    <keep-alive>
      <!--router-view组件是一个 functional 组件，渲染路径匹配到的视图组件-->
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "../src/components/Loading";

export default {
  name: "App",
  components: {
    Loading
  },
};
</script>
<style lang="less">
#app {
  box-sizing: border-box;
  background-color: #ffffff;
}
</style>
