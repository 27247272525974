/* eslint-disable no-console */
import {
    JSEncrypt
} from 'jsencrypt';

export default {
    // RSA 使用公钥加密文本, password 表示需要加密的内容
    encrypt({
        publicKey,
        password
    }) {
        let rsaPassWord = '';
        let encryptor = new JSEncrypt(); // 新建JSEncrypdt对
        let public_Key = window.atob(publicKey)
        encryptor.setPublicKey(public_Key);
        rsaPassWord = encryptor.encrypt(password); // 对密码进行加密
        return rsaPassWord;
    },
    // RSA 使用浏览器端私钥解密 token 得到明文 token 
    decrypt({
        privateKey,
        encryptedText
    }) {
        let text = '';
        try {
            let encryptor = new JSEncrypt();
            encryptor.setPrivateKey(privateKey);
            text = encryptor.decrypt(encryptedText);
        } catch (e) {
            console.error(e);
            text = encryptedText;
        }
        return text;
    },
    // 产生 RSA 密钥对, 返回数组 [私钥, 公钥]; pem 格式, 未使用 base64 加密; PKCS#8
    // 原始公钥使用 base64 编码前需要保持换行格式, 否则报错; 模式字符串需要去除行前空格
};