// 公共方法
import axios from '@/utils/default-axios';
import encryptor from '@/utils/encryptor';
import dayjs from "dayjs";
// import router from '@/router/index'
(function () {
  let ua = window.navigator.userAgent;
  if (ua.indexOf("iPhone") > 0 || ua.indexOf("iPad") > 0) {
    document.body.addEventListener("focusout", () => {
      //软键盘收起的事件处理
      setTimeout(() => {
        const scrollHeight =
          document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.scrollTo(0, Math.max(scrollHeight - 1, 0));
      }, 100);
    });
  }


  window.utils = {
    /* 获取storage */
    projectName: 'assistant',
    storage: {
      getter(key, type) {
        if (type == 1) {
          return JSON.parse(
            sessionStorage.getItem(`${utils.projectName}_${key}`),
          );
        }
        return JSON.parse(
          localStorage.getItem(`${utils.projectName}_${key}`),
        );
      },
      setter(key, val, type) {
        val = JSON.stringify(val);
        if (type == 1) {
          return sessionStorage.setItem(`${utils.projectName}_${key}`, val);
        }
        return localStorage.setItem(`${utils.projectName}_${key}`, val);
      },
      remove(key, type) {
        if (type == 1) {
          return sessionStorage.removeItem(`${utils.projectName}_${key}`);
        }
        return localStorage.removeItem(`${utils.projectName}_${key}`);
      },
    },
  };
  //固定页面显示百分比 不受放大缩小影响
  if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
    }
  }

  function handleFontSize() {
    WeixinJSBridge.invoke('setFontSizeCallback', {
      'fontSize': 0
    });
    WeixinJSBridge.on('menu:setfont', function () {
      WeixinJSBridge.invoke('setFontSizeCallback', {
        'fontSize': 0
      });
    });
  }

}());
const browserPrivKey = `-----BEGIN PRIVATE KEY-----
MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALtYAyWdJncxSqKa
TX8UuxmIIfszfIqPqkpFkCdtNcuVWfmEVVN+s1LybCrkrZkl51bVNtGZjBhS1yGv
0MqTDaWOLlipNvYCfn0x+SxnAUdfWoJakKAZ3jNhRC2ktMziRZx+8M9q7sJrtE6y
rhrq+CYtFcLcIJXLtuCXI2/xeqJhAgMBAAECgYAOcLDBZ/UMj+iUUvdxOaxYQfV4
ktXhKkSyd90uJM1WNVtbj7cx+dyhopzyy3CUEudbepDApCi3wT7i0lXyH1aYT6qu
VWyj2ow9le3dXbYqpDW+XwhCSD+ApTmH+fC1IuH8pO9ZajK5PNgz/j38eZQjJGKW
Yq3G4qfyL5+Q/R4i6QJBAPl0HuHMBErit3N3V1j9BzNrMDZlk74u857KSd8o7l8O
8T9wTHgfgXKmY1wDYAB5V2fq2rVUVfy5vJ5SpkWkme0CQQDAQqAkqqhgaYS3Nkgd
hb2OJ5g9hqICjYc6wF9m8k+YkW6QqcVijC9IZNCiTg3sXV/VukldJEyOtlnwxTPB
nwvFAkEAz9+hYH5X0WOuhk0b/+8TEhkvmxT4klKj914RQyLjyVk1Vq0pPCzc/YKE
aAfz91EmslU2nkbCRREuiizLnFt3XQJBAJYH5/x1CGwkK6LlQyV2plN2ZoEsvOSN
GQgp8sjdMmv2oXdWZGfgyU0PDxh5HLhkrVKVyoZauztq5YjT+u2PdZkCQGsKw45l
T9zlVFz8L2pASIbGFSJdaOOmMJg1hgBfR8BLSn4WxamVMMXdCZTzqXwR+nasbI8P
14aL9H/y1fmy2wE=
-----END PRIVATE KEY-----`;
export const browserPubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7WAMlnSZ3MUqimk1/FLsZiCH7
M3yKj6pKRZAnbTXLlVn5hFVTfrNS8mwq5K2ZJedW1TbRmYwYUtchr9DKkw2lji5Y
qTb2An59MfksZwFHX1qCWpCgGd4zYUQtpLTM4kWcfvDPau7Ca7ROsq4a6vgmLRXC
3CCVy7bglyNv8XqiYQIDAQAB
-----END PUBLIC KEY-----`;
let public_key = ''
//判断是否登录
export function isLogin() {
  let token = window.utils.storage.getter('headerToken', 2);
  let loginInfo = window.utils.storage.getter('loginInfo', 2) || {}
  if (!(token && loginInfo.con_username)) {
    return false
  } else {
    return true
  }
}
//判断是否登录过期  account=1有效 0 失效
export function loginCheck() {
  return new Promise((resolve, reject) => {
    axios.post('patient/login/loginCheck').then(res => {
      if (res.data.account == 1) {
        resolve()
      } else if (res.data.account == 0) {
        login()
      }
    })
  })

}

//获取公钥
export function getPlatformPublicKey() {
  return new Promise((resolve, reject) => {
    axios.post('assistant/platform/getPlatformPublicKey').then(res => {
      public_key = res.data.public_key
      resolve(res.data.public_key)
    }).catch(err => {
      reject(err.message)
    })
  })
}

//登录 储存用户信息
export function login(code = '') {

  return new Promise((resolve, reject) => {
    //需要去获取code
    if (!code) {
      window.utils.storage.remove('openid', 2)
      let str = window.btoa(window.location.href)
      window.location.href = 'https://ca.xlanben.com/getCode2.html?str=' + str
    } else {

      getPlatformPublicKey()
        .then(() => {
          let encryptionCode = encryptor.encrypt({
            publicKey: public_key,
            password: code
          });
          let data = {
            code: encryptionCode,
            public_key: window.btoa(browserPubKey)
          }
          return axios.post('assistant/login/login', data)
        })
        .then(res => {
          let openid = res.data.openid;
          window.utils.storage.setter('openid', openid, 2)
          resolve(openid)
        })
        .catch(err => {
          console.log(err)
        })

    }
  })


}
//判断是否关注 如果返回-99 代表登录过期 需要重新登录 
export function isSubscribe() {
  return new Promise((resolve, reject) => {
    axios.post('patient/login/isSubscribe')
      .then(res => {
        if (res.data.subscribe == 0) {
          reject('未关注')
        } else if (res.data.subscribe == 1) {
          let loginInfo = window.utils.storage.getter('loginInfo', 2) || {}
          if (!loginInfo.nick_name) {
            loginInfo.nick_name = res.data.nickname
            loginInfo.head_img = res.data.headimgurl
            window.utils.storage.setter('loginInfo', loginInfo, 2)
          }
          resolve(res.data.phone)
        }
      })
      .catch(err => {
        if (err.code == -99 || err.code == -98) {
          login()
        }
      })
  })

}



//过滤患者关系
export function judgeRelation(relation) {
  switch (relation) {
    case 1:
      return "本人";
      break;
    case 2:
      return "爸爸";
      break;
    case 3:
      return "妈妈";
      break;
    case 4:
      return "老公";
      break;
    case 5:
      return "老婆";
      break;
    case 6:
      return "女儿";
      break;
    case 7:
      return "儿子";
      break;
    case 8:
      return "朋友";
      break;
    case 9:
      return "家人";
      break;
    default:
      return "";
  }
}

//判断是否是中文
export function isChinese(str) {
  let reg = /^[\u4E00-\u9FA5]+$/;
  return reg.test(str)
}
//判断是否是手机
export function isPhone(phone) {
  return /^1(3\d|4\d|5\d|6\d|7\d|8\d|9\d)\d{8}$/g.test(phone);
}
//判断是否是正常字符串
export function isName(str) {
  let flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
  return flag.test(str)

}
//判断是否身份证
export function isCard(id) {
  if (id.length == 15) {
    return true
  }
  let format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
  return format.test(id)
}
//除去特殊字符
export function filterText(text) {
  text = text.replace(/[&\|\\\*^%$#@,\-]/g, "");
  return text;
};

export function isPwd(pwd) {
  let format = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{6,10})$/
  return format.test(pwd)
}

//发起微信支付
export function wxPay(data) {
  return new Promise((resolve, reject) => {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        "appId": data.appId,
        "timeStamp": data.timeStamp,
        "nonceStr": data.nonceStr,
        "package": data.package,
        "signType": data.signType,
        "paySign": data.paySign
      },
      (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          resolve('支付成功')

        } else {
          reject('支付失败')
        }
      });
  })
}


//处理通用自定义时间
export function formatTime(time) {
  if (typeof (time) != 'number') {
    time = parseInt(time)
  }
  if (time > 100000000000) {
    time = dayjs(time)
  } else {
    time = dayjs.unix(time)
  }
  let now = dayjs().startOf("day")
  let diff = now.diff(time, "day");
  if (diff == 0 && time > now) {
    return time.format("HH:mm");
  } else if (diff == 0 && time < now) {
    return time.format("昨天 HH:mm");
  } else {
    return time.format("MM月DD日 HH:mm:ss");
  }


}
//type: 0, //判断当前页面环境 1 小程序 2安卓 3IOS 4微信浏览器
export function DetermineEnvironment() {
  let u = navigator.userAgent;
  let ua = window.navigator.userAgent.toLowerCase();
  if (u.match(/miniProgram/)) {
    return 1;
  } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return 4;
  } else if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return 3;
  } else {
    return 2;
  }
}