import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex/index";
import axios from "@/utils/default-axios";
import dayjs from "dayjs";
import "./utils/common";
import "./css/common.less";
import "vant/lib/icon/local.css";
// import VConsole from 'vconsole'
// let vConsole = new VConsole();
Vue.config.productionTip = false;
// window.onpageshow = function (e) {
//   if (e.persisted) {
//     window.location.reload(true)
//   }
// }
import {
  Dialog,
  Button,
  RadioGroup,
  Radio,
  Form,
  Field,
  CellGroup,
  Icon,
  ActionSheet,
  ImagePreview,
  Toast,
  Tab, Tabs,Popup,DatetimePicker,Notify 
} from "vant";

Vue.use(Dialog)
  .use(Button)
  .use(RadioGroup)
  .use(Radio)
  .use(Field)
  .use(CellGroup)
  .use(ActionSheet)
  .use(Icon)
  .use(ImagePreview)
  .use(Toast)
  .use(Tab) 
  .use(Tabs)
  .use(DatetimePicker)
  .use(Popup)
  .use(Notify);

Vue.prototype.$dayjs = dayjs;
let Base64 = require("js-base64").Base64;
Vue.prototype.$Base64 = Base64;
let winWidth = document.body.clientWidth;
let size = (winWidth / 750) * 100;
document.documentElement.style.setProperty(
  "font-size",
  (size < 100 ? size : 100) + "px",
  "important"
);
window.utils.storage.setter('firstUrl',window.location.href,1);
Vue.prototype.$http = axios;
router.onError((error) => {
  const pattern = /chunk/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  console.log("路由报错");
  console.log(error);
  if (isChunkLoadFailed) {
    // window.location.href = targetPath
  }
});
import "./assets/fonts/font.css";
router.afterEach((to, from) => {
  document.title = to.meta.title || "歧黄医官";
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
