import Vue from 'vue'
import axios from 'axios';
import router from '@/router'
import {
  Toast
} from 'vant'
Vue.use(Toast)
let api = ""
if (process.env.NODE_ENV !== 'production') {
  api = '/api';
}else{
  api = window.origin+'/'
}
axios.defaults.baseURL = api
axios.defaults.timeout = 100000;
axios.interceptors.request.use(
  (config) => {
    
    if (config.method == 'get') {
      let random = {
        random: new Date().getTime()
      }
      if (!config.data) {
        config.data = random
      } else {
        config.data.random = new Date().getTime()
      }
    }
    let openid = window.utils.storage.getter('openid', 2);
    if (openid) {
      
     if(config.url=='assistant/cashout/submitDoctorCashout'&&config.data&&config.data.type==8){
      //学堂小程序不能在提现时发送openid
     }else{
      config.headers.openid = openid
     }
     
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axios.interceptors.response.use(
  (response) => {
    if (response.data.code && response.data.code !== 0) {
      if (response.data.message == '无权操作') {
        router.push('/error')
      } else if(response.data.code == -3 && response.data.message == '当前活动无权参与'){ //医生新锐活动接口不拦截
        // return Promise.reject(response.data);
        return console.log(response.data)
      } else {
        Toast(response.data)
        return Promise.reject(response.data);
      }

    } else {
      return response.data;
    }
  },
  (error) => {

    return Promise.reject(error);
  }
);

export default axios;